<template>
<transition name="fadePage" mode="out-in">
<div class="total_computing PurchasingTotalComputing">
  <Top-Nav></Top-Nav>
  <Loading-Dialog v-if="isLoading"></Loading-Dialog>
  <div class="node_title">
    <div class="title">{{totalPow | numFormat2}}</div>
    <div class="s_title">{{$t('menu.qwzsl')}}</div>
  </div>
  <div class="node_table" :style="boxHeightObj">
    <div class="list_wrapper" :style="boxHeightObj" ref="scroll">
    <!-- <div class="list_wrapper" :style="boxHeightObj" ref="scroll" @scroll.passive="scrollEvent"> -->
      <div class="list">
        <div class="item" v-for="(item,index) in dataList" :key="index">
          <div class="title_lab">
            <span class="date_i">{{formatDateTimeMethod(item.timestamp)}}</span>
            <img v-if="item.length<3" class="icon_toggle" src="../../../../../public/images/icon_top.png" alt="">
            <img v-if="item.length>2" class="icon_toggle" src="../../../../../public/images/icon_bottom.png" alt="">
          </div>
          <div class="info">
            <i>{{$t('menu.finalCalculationForce')}}</i><span>{{formatAmountMethod(item.totalPow)}}</span>
          </div>
          <div class="info">
            <i>{{$t('menu.totalOutputStar')}}</i><span>{{formatAmountMethod(item.totalStar)}}</span>
          </div>
          <div class="info">
            <i>{{$t('menu.starOutput')}}</i><span>{{formatAmountMethod(item.starPerThousand)}}</span>
          </div>
          <div class="info">
            <i>{{$t('menu.rewardsStar')}}</i><span>{{formatAmountMethod(item.nodePerStar)}}</span>
          </div>
        </div>
      </div>
      <div class="s_title" v-if="isLodingOk"><button class="blue-text" @click="loadMore">{{loadText}}</button></div>
    </div>
  </div>
</div>
</transition>
</template>
<script>
import '../../../../style/totalComputing.css';
import '../styles/common.css';
import TopNav from '../common/PurchasingTop.vue';
import LoadingDialog from '../../../../components/common/LoadingDialog.vue';

const poolTwo = require('../../../../utils/poolTwo')

export default {
  name: 'PurchasingTotalComputing',
  components:{TopNav,LoadingDialog},
  data() {
    return {
      formatAmountMethod:poolTwo.amountConversion,
      formatDateTimeMethod:poolTwo.getDateTime,
      totalPow:0,// 全网算力
      totalSwap:0,//全网质押总额
      windowHeight: window.innerHeight,   //屏幕高度
      boxHeightObj:{  //背景框的高度
        height:0
      }, 
      isLoading:true, //加载蒙版
      isLodingOk:true,
      first:5,
      skip:0,
      loadText:this.$t('menu.loadMoreText'),
      dataList:[
      //   {
      //   date_t:"2022-05-11",
      //   status:0,
      //   info_list:[{
      //     name:'finalCalculationForce',
      //     num:'1200.82'
      //   },{
      //     name:'totalOutputStar',
      //     num:'1200.82'
      //   },{
      //     name:'starOutput',
      //     num:'1200.82',
      //   },{
      //     name:'rewardsStar',
      //     num:'1200.82',
      //   }]
      // }
      // ,{
      //   date_t:"2022-05-11",
      //   status:1,
      //   info_list:[{
      //     name:'finalCalculationForce',
      //     num:'1200.82'
      //   },{
      //     name:'totalOutputStar',
      //     num:'1200.82'
      //   },{
      //     name:'starOutput',
      //     num:'1200.82',
      //   },{
      //     name:'rewardsStar',
      //     num:'1200.82',
      //   }]
      // }
      ]
    }
  },
  created:function(){
    let that = this
    //全网算力
    poolTwo.poolInfo().then(res=>{
      that.totalPow = res._totalPow // 全网算力
      that.totalSwap = res._totalSwap // 全网质押总额
    })
    
    that.getPowSummary() // 每日全网数据
  },
  mounted(){
    this.boxHeightObj.height = (this.windowHeight/10)-5-10.8-3.2+'rem';

    // 每日全网数据

  },
  methods:{
    loadMore(){
      this.first +=1;
      this.getPowSummary();
    },
    // 每日全网数据
    getPowSummary(){
      let that = this;
      let first = that.first;
      let skip = that.skip;
      that.skip = first + skip;
      poolTwo.PowSummary(first,skip).then(res=>{        
        // that.flag = 0;
        let powSummaryModels = res.data.powSummaryModels;
        console.log(powSummaryModels);
        that.isLoading = false;
        let powSummaryModelsLength =  powSummaryModels.length;
        let dataListLength =  that.dataList.length;
        if(powSummaryModelsLength<that.first&&powSummaryModelsLength!=0){
          that.isLodingOk = false; // 总数小于请求条数，隐藏加载更多按钮
        }
        let dataList = that.dataList;
        powSummaryModels.forEach(item=>{
          dataList.push(item);
        })
        that.dataList = dataList;
        if(powSummaryModelsLength==0){
          that.loadText = this.$t('menu.noDataText');//暂无数据
        }else{
          if(powSummaryModelsLength==dataListLength){
            that.loadText = this.$t('menu.noMoreText');//没有更多了
          }else{
            that.loadText = this.$t('menu.loadMoreText');//点击加载更多
          }
          
        }
      })
    },
    // 滚动事件
    // scrollEvent() {
    //   // 获取容器盒子的dom
    //   let that =this
    //   let scroll= this.$refs.scroll;
    //   let flag =this.flag
    //   let wait = this.wait

    //   // console.log('获取容器盒子的dom',scroll,scroll.scrollHeight - scroll.scrollTop ,scroll.clientHeight,Math.ceil(scroll.scrollHeight - scroll.scrollTop+5),flag,that.isLodingOk)
    //   // 判断滚动条距离容器底部的位置 当滚动条距离容器底部只有5像素时触发
    //   if (Math.ceil(scroll.scrollHeight - scroll.scrollTop) == scroll.clientHeight) {
    //     if (flag) return;
    //     flag = 1; // 执行完一次后 关闭节流阀
    //     if (that.isLodingOk) return;
        
    //     // setTimeout 根据个人需求来定 
    //     that.isLoading = true
    //     setTimeout(() => {
    //       // 需要执行的操作 执行完成后开启节流阀flag = 0;
    //       // 如果不需要在进行操作 flag无需改变或者flag = 1;
    //       that.getPowSummary()
    //     }, wait);
    //   }
    // },
  }
}
</script>
<style scoped>
</style>